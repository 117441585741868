// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  open: false,
  title: null,
  content: null
};

// ==============================|| SLICE - MENU ||============================== //

const dialog = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog(state, action) {
      state.open = true;
      state.content = action.payload.content;
      state.title = action.payload.title;
    },

    closeDialog(state) {
      state.open = false;
      state.content = initialState.content;
      state.title = initialState.title;
    }
  }
});

export default dialog.reducer;

export const { openDialog, closeDialog } = dialog.actions;
