import requester from '../requester';

const create = (data) => {
  return requester.post('/api/engineering-schedules', data);
};

const getAll = (filters) => {
  return requester.get(`/api/engineering-schedules?${new URLSearchParams(filters)}`);
};

const remove = (id) => {
  return requester.delete(`/api/engineering-schedules/${id}`);
};

const update = (data, id) => {
  return requester.put(`/api/engineering-schedules/${id}`, data);
};

const SectorEngineeringScheduleService = {
  create,
  getAll,
  remove,
  update
};

export default SectorEngineeringScheduleService;
