// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import alert from './alert';
import auth from './auth';
import dialog from './dialog';
import user from './user';
import tollPlaza from './tollPlaza';
import sectorTraffic from './sectorTraffic';
import sectorConservation from './sectorConservation';
import team from './team';
import sectorEnvironment from './sectorEnvironment';
import sectorEngineering from './sectorEngineering';
import plan from './plan';
import sectorOperation from './sectorOperation';
import log from './log';
import sectorEngineeringSchedule from './sectorEngineeringSchedule';
import sectorEngineeringScheduleProgress from './sectorEngineeringScheduleProgress';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  alert,
  auth,
  dialog,
  user,
  tollPlaza,
  sectorTraffic,
  sectorConservation,
  team,
  sectorEnvironment,
  sectorEngineering,
  plan,
  sectorOperation,
  log,
  sectorEngineeringSchedule,
  sectorEngineeringScheduleProgress
});

export default reducers;
