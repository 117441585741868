import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import ConfirmAccount from 'pages/authentication/ConfirmAccount';
import { Navigate } from 'react-router-dom';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')));
const AuthRecoverPassword = Loadable(lazy(() => import('pages/authentication/RecoverPassword')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'esqueci-minha-senha',
      element: <AuthForgotPassword />
    },
    {
      path: 'password-reset/:token',
      element: <AuthRecoverPassword />
    },
    {
      path: 'confirm-account/:id/:hash',
      element: <ConfirmAccount />
    },
    {
      path: '*',
      element: <Navigate to={'/'} />
    }
  ]
};

export default LoginRoutes;
