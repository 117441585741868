import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import sectorConservationService from '../../services/SectorConservationService';
import { closeDialog } from './dialog';

const initialState = {
  sendingData: false,
  fetchingSectorData: false,
  conservationData: []
};

export const getConservation = createAsyncThunk('conservation/getAll', async (data, { rejectWithValue, fulfillWithValue }) => {
  return sectorConservationService
    .getAll(data)
    .then((res) => {
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

export const createConservation = createAsyncThunk(
  'sector_traffic/create',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Criando registro...');
    return sectorConservationService
      .create(data)
      .then((res) => {
        toast.success('Registro criado com sucesso!');
        dispatch(getConservation());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao criar o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const updateConservation = createAsyncThunk(
  'sector_traffic/update',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Alterando registro...');
    return sectorConservationService
      .update(data, data.id)
      .then((res) => {
        toast.success('Registro atualizado com sucesso!');
        dispatch(getConservation({}));
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao atualizar o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const removeConservation = createAsyncThunk(
  'sector_traffic/remove',
  async (ids, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Removendo registro...');
    return sectorConservationService
      .remove(ids)
      .then((res) => {
        toast.success('Registro removido com sucesso!');
        dispatch(getConservation({}));
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao remover o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

const conservation = createSlice({
  name: 'conservation',
  initialState,
  reducers: {},
  extraReducers: {
    [getConservation.fulfilled]: (state, action) => {
      state.conservationData = action.payload.data;
      state.fetchingSectorData = false;
    },
    [getConservation.rejected]: (state) => {
      state.conservationData = [];
      state.fetchingSectorData = false;
    },
    [getConservation.pending]: (state) => {
      state.fetchingSectorData = true;
    }
  }
});

const { reducer } = conservation;

export const {} = conservation.actions;

export default reducer;
