// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import TutorialDataService from "../services/TutorialService";
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import logService from '../../services/LogService';

const initialState = {
  logs: [],
  fetching: false
};

export const getLogs = createAsyncThunk('log/getLogs', async (filters, { rejectWithValue, fulfillWithValue }) => {
  return logService
    .getAll(filters)
    .then((res) =>
      fulfillWithValue({
        status: res.status,
        data: res.data
      })
    )
    .catch((err) => {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

const log = createSlice({
  name: 'log',
  initialState,
  reducers: {},
  extraReducers: {
    [getLogs.rejected]: (state) => {
      state.fetching = false;
    },
    [getLogs.pending]: (state) => {
      state.fetching = true;
    },
    [getLogs.fulfilled]: (state, action) => {
      state.fetching = false;
      state.logs = action.payload.data;
    }
  }
});

const { reducer } = log;

export const {} = log.actions;

export default reducer;
