import requester from '../requester';

const create = (data) => {
  return requester.post('/api/engineerings', data);
};

const getAll = (filters) => {
  return requester.get(`/api/engineerings?${new URLSearchParams(filters)}`);
};

const remove = (id) => {
  return requester.delete(`/api/engineerings/${id}`);
};

const update = (data, id) => {
  return requester.put(`/api/engineerings/${id}`, data);
};

const SectorEngineeringService = {
  create,
  getAll,
  remove,
  update
};

export default SectorEngineeringService;
