// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import TutorialDataService from "../services/TutorialService";
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import userService from '../../services/UserService';
import { closeDialog } from './dialog';
import { toast } from 'react-toastify';

const initialState = {
  creatingUser: false,
  createUserDetail: null,
  fetchingUsers: true,
  users: [],
  currentPageUsers: 1
};

export const createUser = createAsyncThunk(
  'user/create',
  async ({ name, email, password, permissions }, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Criando usuário...');
    return userService
      .create({ name, email, password, permissions })
      .then((res) => {
        toast.success('Usuário criado com sucesso!');
        dispatch(closeDialog());
        dispatch(getUsers({ page: 1, total: 10 }));
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao criar o usuário');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const updateUser = createAsyncThunk(
  'user/update',
  async ({ id, name, permissions }, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    const loading = toast.loading('Alterando usuário...');

    return userService
      .update({ id, name, permissions })
      .then((res) => {
        toast.success('Usuário alterado com sucesso!');
        dispatch(closeDialog());
        dispatch(getUsers({ page: getState().user.currentPageUsers, total: 10 }));

        setTimeout(() => {
          dispatch(resetMessagesCreateUser());
        }, 3000);

        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao alterar o usuário');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const getUsers = createAsyncThunk('user/getAll', async ({ page = 1, total = 10 }, { rejectWithValue, fulfillWithValue }) => {
  return userService
    .getAll({ page, total })
    .then((res) =>
      fulfillWithValue({
        status: res.status,
        data: res.data
      })
    )
    .catch((err) => {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

export const deleteUser = createAsyncThunk('user/delete', async ({ id }, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
  return userService
    .remove({ id })
    .then((res) => {
      console.log(getState());
      dispatch(getUsers({ page: 1, total: 10 }));
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      console.log(err);
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetMessagesCreateUser: (state) => {
      state.createUserDetail = null;
    }
  },
  extraReducers: {
    [createUser.rejected]: (state, action) => {
      state.creatingUser = false;
      state.createUserDetail = action.payload;
    },
    [createUser.pending]: (state) => {
      state.creatingUser = true;
      state.createUserDetail = null;
    },
    [createUser.fulfilled]: (state, action) => {
      state.creatingUser = false;
      state.createUserDetail = action.payload;
    },

    [updateUser.rejected]: (state, action) => {
      state.creatingUser = false;
      state.createUserDetail = action.payload;
    },
    [updateUser.pending]: (state) => {
      state.creatingUser = true;
      state.createUserDetail = null;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.creatingUser = false;
      state.createUserDetail = action.payload;
    },

    [getUsers.fulfilled]: (state, action) => {
      state.fetchingUsers = false;
      state.users = action.payload.data;
    },
    [getUsers.pending]: (state) => {
      state.fetchingUsers = true;
    },
    [getUsers.rejected]: (state) => {
      state.fetchingUsers = false;
      state.users = [];
    },

    [deleteUser.fulfilled]: (state, action) => {
      state.createUserDetail = action.payload;
    },
    [deleteUser.pending]: (state) => {
      state.createUserDetail = null;
    },
    [deleteUser.rejected]: (state) => {
      state.createUserDetail = null;
    }
  }
});

const { reducer } = user;

export const { resetMessagesCreateUser } = user.actions;

export default reducer;
