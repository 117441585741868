import requester from '../requester';

const create = (data) => {
  return requester.post('/api/accidents', data);
};

const getAll = (data) => {
  return requester.get(`/api/accidents?${new URLSearchParams(data)}`);
};

const remove = (id) => {
  return requester.delete(`/api/accidents/${id}`);
};

const update = (data, id) => {
  return requester.put(`/api/accidents/${id}`, data);
};

const SectorOperationsAccidents = {
  create,
  getAll,
  remove,
  update
};

export default SectorOperationsAccidents;
