import requester from '../requester';

const create = ({ name }) => {
  return requester.post('/api/toll-plazas', { name });
};

const getAll = () => {
  return requester.get(`/api/toll-plazas`);
};

const TollPlazasService = {
  create,
  getAll
};

export default TollPlazasService;
