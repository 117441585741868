import requester from '../requester';

const create = (data) => {
  return requester.post('/api/engineering-schedule-progress', data);
};

const remove = (id) => {
  return requester.delete(`/api/engineering-schedule-progress/${id}`);
};

const update = (data, id) => {
  return requester.put(`/api/engineering-schedule-progress/${id}`, data);
};

const SectorEngineeringScheduleProgressService = {
  create,
  remove,
  update
};

export default SectorEngineeringScheduleProgressService;
