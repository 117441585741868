import requester from '../requester';

const update = (data, id) => {
  return requester.put(`/api/accident-registers/${id}`, data);
};

const RegisterAccidentsService = {
  update
};

export default RegisterAccidentsService;
