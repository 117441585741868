import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import sectorEngineeringRowService from '../../services/SectorEngineeringRowService';
import sectorEngineeringService from '../../services/SectorEngineeringService';
import { closeDialog } from './dialog';

const initialState = {
  sendingData: false,
  sendingDataRow: false,
  fetchingData: false,
  engineering: []
};

export const getEngineering = createAsyncThunk('engineering/getAll', async (filters, { rejectWithValue, fulfillWithValue }) => {
  return sectorEngineeringService
    .getAll(filters)
    .then((res) => {
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

export const createEngineering = createAsyncThunk('engineering/create', async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
  const loading = toast.loading('Criando registro...');
  return sectorEngineeringService
    .create(data)
    .then((res) => {
      toast.success('Registro criado com sucesso!');
      dispatch(getEngineering());
      dispatch(closeDialog());
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      toast.error(err.response.data.error ?? 'Falha ao criar registro');
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    })
    .finally(() => {
      toast.dismiss(loading);
    });
});

export const createEngineeringRow = createAsyncThunk(
  'engineering/create/row',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Criando registro...');
    return sectorEngineeringRowService
      .create(data)
      .then((res) => {
        toast.success('Registro criado com sucesso!');
        dispatch(getEngineering());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao criar registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const updateEngineering = createAsyncThunk('engineering/update', async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
  const loading = toast.loading('Alterando registro...');
  return sectorEngineeringService
    .update(data, data.uuid)
    .then((res) => {
      toast.success('Registro atualizado com sucesso!');
      dispatch(getEngineering());
      dispatch(closeDialog());
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      toast.error(err.response.data.error ?? 'Falha ao atualizar o registro');
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    })
    .finally(() => {
      toast.dismiss(loading);
    });
});

export const updateEngineeringRow = createAsyncThunk(
  'engineering/update/row',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Alterando registro...');
    return sectorEngineeringRowService
      .update(data, data.uuid)
      .then((res) => {
        toast.success('Registro atualizado com sucesso!');
        dispatch(getEngineering());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao atualizar o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const removeEngineering = createAsyncThunk('engineering/remove', async (id, { rejectWithValue, fulfillWithValue, dispatch }) => {
  const loading = toast.loading('Removendo registro...');
  return sectorEngineeringService
    .remove(id)
    .then((res) => {
      toast.success('Registro removido com sucesso!');
      dispatch(getEngineering());
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      toast.error('Falha ao remover o registro');
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    })
    .finally(() => {
      toast.dismiss(loading);
    });
});

export const removeEngineeringRow = createAsyncThunk(
  'engineering/remove/row',
  async (id, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Removendo registro...');
    return sectorEngineeringRowService
      .remove(id)
      .then((res) => {
        toast.success('Registro removido com sucesso!');
        dispatch(getEngineering());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao remover o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

const engineering = createSlice({
  name: 'engineering',
  initialState,
  reducers: {},
  extraReducers: {
    [getEngineering.fulfilled]: (state, action) => {
      state.engineering = action.payload.data;
      state.fetchingData = false;
    },
    [getEngineering.rejected]: (state) => {
      state.engineering = [];
      state.fetchingData = false;
    },
    [getEngineering.pending]: (state) => {
      state.fetchingData = true;
    },
    [createEngineering.fulfilled]: (state) => {
      state.sendingData = false;
    },
    [createEngineering.rejected]: (state) => {
      state.sendingData = false;
    },
    [createEngineering.pending]: (state) => {
      state.sendingData = true;
    },
    [updateEngineering.fulfilled]: (state) => {
      state.sendingData = false;
    },
    [updateEngineering.rejected]: (state) => {
      state.sendingData = false;
    },
    [updateEngineering.pending]: (state) => {
      state.sendingData = true;
    },
    [createEngineeringRow.fulfilled]: (state) => {
      state.sendingDataRow = false;
    },
    [createEngineeringRow.rejected]: (state) => {
      state.sendingDataRow = false;
    },
    [createEngineeringRow.pending]: (state) => {
      state.sendingDataRow = true;
    },
    [updateEngineeringRow.fulfilled]: (state) => {
      state.sendingDataRow = false;
    },
    [updateEngineeringRow.rejected]: (state) => {
      state.sendingDataRow = false;
    },
    [updateEngineeringRow.pending]: (state) => {
      state.sendingDataRow = true;
    }
  }
});

const { reducer } = engineering;

export const {} = engineering.actions;

export default reducer;
