import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - dashboard
const SectorTraffic = Loadable(lazy(() => import('pages/sector-traffic-public/SectorTraffic')));
const SectorConservation = Loadable(lazy(() => import('pages/sector-conservation-public/SectorConservation')));
const SectorEnvironment = Loadable(lazy(() => import('pages/sector-environment-public/SectorEnvironment')));
const SectorEngineering = Loadable(lazy(() => import('pages/sector-engineering-public/SectorEngineering')));
const SectorEngineeringSchedule = Loadable(lazy(() => import('pages/sector-engineering-public/SectorEngineeringSchedule')));
const SectorOperationAccident = Loadable(lazy(() => import('pages/sector-operation-public/SectorOperationAccident')));
const SectorOperationRestriction = Loadable(lazy(() => import('pages/sector-operation-public/SectorOperationRestriction')));

// ==============================|| MAIN ROUTING ||============================== //

const PublicRoutes = {
  path: '/publico',
  element: <MinimalLayout />,
  children: [
    {
      path: 'setor-cca',
      element: <SectorTraffic />
    },
    {
      path: 'setor-conservacao',
      element: <SectorConservation />
    },
    {
      path: 'setor-meio-ambiente',
      element: <SectorEnvironment />
    },
    {
      path: 'setor-engenharia/:uuid',
      element: <SectorEngineering />
    },
    {
      path: 'setor-engenharia',
      element: <SectorEngineering />
    },
    {
      path: 'setor-engenharia-cronogramas',
      element: <SectorEngineeringSchedule />
    },
    {
      path: 'setor-operacao-acidentes/:uuid',
      element: <SectorOperationAccident />
    },
    {
      path: 'setor-operacao-acidentes',
      element: <SectorOperationAccident />
    },
    {
      path: 'setor-operacao-restricoes',
      element: <SectorOperationRestriction />
    }
  ]
};

export default PublicRoutes;
