import requester from '../requester';

const create = (data) => {
  return requester.post('/api/road-restrictions', data);
};

const getAll = () => {
  return requester.get(`/api/road-restrictions`);
};

const remove = (id) => {
  return requester.delete(`/api/road-restrictions/${id}`);
};

const update = (data, id) => {
  return requester.put(`/api/road-restrictions/${id}`, data);
};

const SectorOperationRestrictionService = {
  create,
  getAll,
  remove,
  update
};

export default SectorOperationRestrictionService;
