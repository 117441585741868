import requester from '../requester';

const getAll = (filters) => {
  return requester.get(`/api/logs?${new URLSearchParams(filters)}`);
};

const LogService = {
  getAll
};

export default LogService;
