// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import TutorialDataService from "../services/TutorialService";
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import authService from '../../services/AuthService';

const initialState = {
  loading: false,
  sendingForgot: false,
  forgotDetail: null,
  user: null,
  fetchingRecover: true,
  recoverDetail: null,
  savingNewPassword: false,
  confirmationAccount: false,
  confirmeAccountDetail: null,
  recoverNewPasswordDetail: null,
  fetchingMe: false,
  me: null
};

export const login = createAsyncThunk('auth/login', async ({ email, password }, { rejectWithValue, fulfillWithValue }) => {
  const loading = toast.loading('Autenticando...');
  return authService
    .login({ email, password })
    .then((res) => {
      localStorage.setItem('token', res.data.access_token);
      toast.success('Login efetuado com sucesso!');

      setTimeout(() => {
        window.location.reload();
      }, 2000);

      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      toast.error(err?.response?.data?.error ?? 'Usuário ou senha inválidos');
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    })
    .finally(() => {
      toast.dismiss(loading);
    });
});

export const me = createAsyncThunk('auth/me', async (_, { rejectWithValue, fulfillWithValue }) => {
  const loading = toast.loading('Buscando dados do usuário...');
  return authService
    .me()
    .then((res) => {
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    })
    .finally(() => {
      toast.dismiss(loading);
    });
});

export const forgotPassword = createAsyncThunk('auth/forgot', async ({ email }, { rejectWithValue, fulfillWithValue }) => {
  return authService
    .forgot({ email })
    .then((res) => {
      toast.success('E-mail de recuperação de senha enviado com sucesso!');

      setTimeout(() => {
        window.location.href = '/';
      }, 2000);

      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      toast.error(
        'Tivemos um problema ao enviar o e-mail de recuperação de senha. Verifique se o e-mail informado está correto e se você já não solicitou.'
      );
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

export const confirmeAccount = createAsyncThunk('auth/confirmeAccount', async ({ id, hash }, { rejectWithValue, fulfillWithValue }) => {
  return authService
    .confirmeAccount({ id, hash })
    .then((res) =>
      fulfillWithValue({
        status: res.status,
        data: res.data
      })
    )
    .catch((err) =>
      rejectWithValue({
        status: err.response.status,
        data: err.response.data
      })
    );
});

export const recoverPassword = createAsyncThunk(
  'auth/recoverPassword',
  async ({ token, password, confirmPassword, email }, { rejectWithValue, fulfillWithValue }) => {
    const loading = toast.loading('Salvando nova senha...');
    try {
      const response = await authService.recover({
        email,
        token,
        password,
        confirmPassword
      });

      toast.success('Senha alterada com sucesso!');
      return fulfillWithValue({ status: response.status });
    } catch (err) {
      toast.error('Erro ao alterar senha, seu link parece expirado!');
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    } finally {
      toast.dismiss(loading);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [login.rejected]: (state) => {
      state.loading = false;
      state.user = null;
      state.token = null;
    },
    [login.pending]: (state) => {
      state.loading = true;
      state.user = null;
      state.token = null;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.token = action.payload.access_token;
    },

    [forgotPassword.rejected]: (state, action) => {
      state.sendingForgot = false;
      state.forgotDetail = action.payload;
    },
    [forgotPassword.pending]: (state) => {
      state.sendingForgot = true;
      state.forgotDetail = null;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.sendingForgot = false;
      state.forgotDetail = action.payload;
    },

    [recoverPassword.rejected]: (state, action) => {
      state.savingNewPassword = false;
      state.recoverNewPasswordDetail = action.payload;
    },
    [recoverPassword.pending]: (state) => {
      state.savingNewPassword = true;
    },
    [recoverPassword.fulfilled]: (state, action) => {
      state.savingNewPassword = false;
      state.recoverNewPasswordDetail = action.payload;
    },

    [me.rejected]: (state) => {
      state.fetchingMe = false;
      state.me = null;
    },
    [me.pending]: (state) => {
      state.fetchingMe = true;
    },
    [me.fulfilled]: (state, action) => {
      state.fetchingMe = false;
      state.me = action.payload.data;
    },

    [confirmeAccount.rejected]: (state, action) => {
      state.confirmationAccount = false;
      state.confirmeAccountDetail = action.payload;
    },
    [confirmeAccount.pending]: (state) => {
      state.confirmationAccount = true;
    },
    [confirmeAccount.fulfilled]: (state, action) => {
      state.confirmationAccount = false;
      state.confirmeAccountDetail = action.payload;
    }
  }
});

const { reducer } = authSlice;
export default reducer;
