// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import TutorialDataService from "../services/TutorialService";
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sectorTrafficService from '../../services/SectorTrafficService';
import { toast } from 'react-toastify';
import { closeDialog } from './dialog';

const initialState = {
  sendingData: false,
  fetchingTraffic: false,
  trafficData: []
};

export const getSectorTraffic = createAsyncThunk('sector_traffic/getAll', async (data, { rejectWithValue, fulfillWithValue }) => {
  return sectorTrafficService
    .getAll(data)
    .then((res) => {
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

export const createSectorTrafficRegister = createAsyncThunk(
  'sector_traffic/create',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Criando registro...');
    return sectorTrafficService
      .create(data)
      .then((res) => {
        toast.success('Registro criado com sucesso!');
        dispatch(getSectorTraffic({}));
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao criar o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const updateSectorTrafficRegister = createAsyncThunk(
  'sector_traffic/update',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Alterando registro...');
    return sectorTrafficService
      .update(data, data.id)
      .then((res) => {
        toast.success('Registro atualizado com sucesso!');
        dispatch(getSectorTraffic({}));
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao atualizar o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const removeSectorTraffic = createAsyncThunk(
  'sector_traffic/remove',
  async (id, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Removendo registro...');
    return sectorTrafficService
      .remove(id)
      .then((res) => {
        toast.success('Registro removido com sucesso!');
        dispatch(getSectorTraffic({}));
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao remover o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

const sectorTraffic = createSlice({
  name: 'sectorTraffic',
  initialState,
  reducers: {},
  extraReducers: {
    [createSectorTrafficRegister.rejected]: (state) => {
      state.sendingData = false;
    },
    [createSectorTrafficRegister.pending]: (state) => {
      state.sendingData = true;
    },
    [createSectorTrafficRegister.fulfilled]: (state) => {
      state.sendingData = false;
    },

    [getSectorTraffic.rejected]: (state) => {
      state.fetchingTraffic = false;
    },
    [getSectorTraffic.pending]: (state) => {
      state.fetchingTraffic = true;
    },
    [getSectorTraffic.fulfilled]: (state, action) => {
      state.fetchingTraffic = false;
      state.trafficData = action.payload.data;
    }
  }
});

const { reducer } = sectorTraffic;

export const {} = sectorTraffic.actions;

export default reducer;
