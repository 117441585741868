// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import TutorialDataService from "../services/TutorialService";
//
import { createSlice } from '@reduxjs/toolkit';
import { forgotPassword, login, recoverPassword } from './auth';

const initialState = {
  open: false,
  message: '',
  severity: ''
};

// export const login = createAsyncThunk(
//   "auth/login",
//   async ({ username, password }) => {
//     const response = await authService.login({ username, password });
//     return response.data;
//   }
// );

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    hideAlert: (state) => {
      return {
        ...state,
        open: false
      };
    }
  },
  extraReducers: {
    [login.rejected]: (state) => {
      return {
        ...state,
        open: true,
        message: 'Usuário ou senha inválidos',
        severity: 'error'
      };
    },
    [forgotPassword.rejected]: (state) => {
      return {
        ...state,
        open: true,
        message: 'Não fo possível enviar o e-mail. Verifique se o e-mail está correto.',
        severity: 'error'
      };
    },
    [forgotPassword.fulfilled]: (state) => {
      return {
        ...state,
        open: true,
        message: 'Enviamos um e-mail para você com instruções parar redefinir sua senha.',
        severity: 'success'
      };
    },
    [recoverPassword.rejected]: (state) => {
      return {
        ...state,
        open: true,
        message: 'Não foi possível redefinir sua senha',
        severity: 'error'
      };
    },
    [recoverPassword.fulfilled]: (state) => {
      return {
        ...state,
        open: true,
        message: 'Senha alterada com sucesso',
        severity: 'success'
      };
    }
  }
});

const { reducer } = alertSlice;

export const { hideAlert } = alertSlice.actions;

export default reducer;
