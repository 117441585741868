import requester from '../requester';

const create = ({ name, email, password, permissions }) => {
  return requester.post('/api/register', { name, email, password, password_confirmation: password, permissions });
};

const getAll = ({ page = 1, total = 10 }) => {
  return requester.get(`/api/users?page=${page}&total=${total}`);
};

const remove = ({ id }) => {
  return requester.delete(`/api/users/${id}`);
};

const update = ({ id, name, permissions }) => {
  return requester.put(`/api/users/${id}`, { name, permissions });
};

const UserService = {
  create,
  getAll,
  remove,
  update
};

export default UserService;
