import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../store/reducers/dialog';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function CustomizedDialogs() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.dialog.open);
  const title = useSelector((state) => state.dialog.title);
  const content = useSelector((state) => state.dialog.content);

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} maxWidth={'md'} fullWidth={true}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => dispatch(closeDialog())}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>{content}</DialogContent>
      </BootstrapDialog>
    </div>
  );
}
