import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sectorEngineeringScheduleService from '../../services/SectorEngineeringScheduleService';
import { toast } from 'react-toastify';
import { closeDialog } from './dialog';

const initialState = {
  sendingData: false,
  fetchingData: false,
  engineeringSchedules: [],
  plans: []
};

export const getEngineeringSchedule = createAsyncThunk(
  'engineeringSchedule/getAll',
  async (filters, { rejectWithValue, fulfillWithValue }) => {
    return sectorEngineeringScheduleService
      .getAll(filters)
      .then((res) => {
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      });
  }
);

export const createEngineeringSchedule = createAsyncThunk(
  'engineeringSchedule/create',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Criando registro...');
    return sectorEngineeringScheduleService
      .create(data)
      .then((res) => {
        toast.success('Registro criado com sucesso!');
        dispatch(getEngineeringSchedule());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao criar registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const updateEngineeringSchedule = createAsyncThunk(
  'engineeringSchedule/update',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Alterando registro...');
    return sectorEngineeringScheduleService
      .update(data, data.uuid)
      .then((res) => {
        toast.success('Registro atualizado com sucesso!');
        dispatch(getEngineeringSchedule());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao atualizar o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const removeEngineeringSchedule = createAsyncThunk(
  'engineeringSchedule/remove',
  async (id, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Removendo registro...');
    return sectorEngineeringScheduleService
      .remove(id)
      .then((res) => {
        toast.success('Registro removido com sucesso!');
        dispatch(getEngineeringSchedule());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao remover o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

const engineeringSchedule = createSlice({
  name: 'engineeringSchedule',
  initialState,
  reducers: {},
  extraReducers: {
    [getEngineeringSchedule.fulfilled]: (state, action) => {
      state.engineeringSchedules = action.payload.data;
      state.plans = Object.keys(action.payload.data);
      state.fetchingData = false;
    },
    [getEngineeringSchedule.rejected]: (state) => {
      state.engineeringSchedules = [];
      state.plans = [];
      state.fetchingData = false;
    },
    [getEngineeringSchedule.pending]: (state) => {
      state.fetchingData = true;
    },
    [createEngineeringSchedule.fulfilled]: (state) => {
      state.sendingData = false;
    },
    [createEngineeringSchedule.rejected]: (state) => {
      state.sendingData = false;
    },
    [createEngineeringSchedule.pending]: (state) => {
      state.sendingData = true;
    },
    [updateEngineeringSchedule.fulfilled]: (state) => {
      state.sendingData = false;
    },
    [updateEngineeringSchedule.rejected]: (state) => {
      state.sendingData = false;
    },
    [updateEngineeringSchedule.pending]: (state) => {
      state.sendingData = true;
    }
  }
});

const { reducer } = engineeringSchedule;

export const {} = engineeringSchedule.actions;

export default reducer;
