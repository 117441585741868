import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import PublicRoutes from './PublicRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const token = localStorage.getItem('token');

  let mainRoutes = MainRoutes;

  const routes = token ? mainRoutes : LoginRoutes;

  return useRoutes([routes, PublicRoutes]);
}
