// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { ConfirmProvider } from 'material-ui-confirm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <ThemeCustomization>
    <ScrollTop>
      <ConfirmProvider
        defaultOptions={{
          title: 'Você tem certeza?',
          cancellationText: 'Não',
          confirmationText: 'Sim',
          allowClose: false
        }}
      >
        <Routes />
      </ConfirmProvider>
      <ToastContainer />
    </ScrollTop>
  </ThemeCustomization>
);

export default App;
