import requester from '../requester';

const create = (data) => {
  return requester.post('/api/engineering-rows', data);
};

const remove = (id) => {
  return requester.delete(`/api/engineering-rows/${id}`);
};

const update = (data, id) => {
  return requester.put(`/api/engineering-rows/${id}`, data);
};

const SectorEngineeringRowService = {
  create,
  remove,
  update
};

export default SectorEngineeringRowService;
