import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sectorEnvironmentService from '../../services/SectorEnvironmentService';
import { toast } from 'react-toastify';
import { closeDialog } from './dialog';

const initialState = {
  sendingData: false,
  fetchingData: false,
  environments: []
};

export const getEnvironment = createAsyncThunk('environment/getAll', async (_, { rejectWithValue, fulfillWithValue }) => {
  return sectorEnvironmentService
    .getAll({})
    .then((res) => {
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

export const createEnvironment = createAsyncThunk('environment/create', async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
  const loading = toast.loading('Criando registro...');
  return sectorEnvironmentService
    .create(data)
    .then((res) => {
      toast.success('Registro criado com sucesso!');
      dispatch(getEnvironment());
      dispatch(closeDialog());
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      toast.error(err.response.data.error ?? 'Falha ao criar registro');
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    })
    .finally(() => {
      toast.dismiss(loading);
    });
});

export const updateEnvironment = createAsyncThunk('environment/update', async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
  const loading = toast.loading('Alterando registro...');
  return sectorEnvironmentService
    .update(data, data.id)
    .then((res) => {
      toast.success('Registro atualizado com sucesso!');
      dispatch(getEnvironment());
      dispatch(closeDialog());
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      toast.error(err.response.data.error ?? 'Falha ao atualizar o registro');
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    })
    .finally(() => {
      toast.dismiss(loading);
    });
});

export const removeEnvironment = createAsyncThunk('environment/remove', async (id, { rejectWithValue, fulfillWithValue, dispatch }) => {
  const loading = toast.loading('Removendo registro...');
  return sectorEnvironmentService
    .remove(id)
    .then((res) => {
      toast.success('Registro removido com sucesso!');
      dispatch(getEnvironment());
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      toast.error('Falha ao remover o registro');
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    })
    .finally(() => {
      toast.dismiss(loading);
    });
});

const environment = createSlice({
  name: 'environment',
  initialState,
  reducers: {},
  extraReducers: {
    [getEnvironment.fulfilled]: (state, action) => {
      state.environments = action.payload.data;
      state.fetchingData = false;
    },
    [getEnvironment.rejected]: (state) => {
      state.environments = [];
      state.fetchingData = false;
    },
    [getEnvironment.pending]: (state) => {
      state.fetchingData = true;
    },
    [createEnvironment.fulfilled]: (state) => {
      state.sendingData = false;
    },
    [createEnvironment.rejected]: (state) => {
      state.sendingData = false;
    },
    [createEnvironment.pending]: (state) => {
      state.sendingData = true;
    }
  }
});

const { reducer } = environment;

export const {} = environment.actions;

export default reducer;
