import requester from '../requester';

const login = ({ email, password }) => {
  return requester.post('/api/login', { email, password });
};

const forgot = ({ email }) => {
  return requester.post('/api/forgot-password', { email });
};

const me = () => {
  return requester.post('/api/me');
};

const confirmeAccount = ({ id, hash }) => {
  return requester.post('/api/verify-email', { id, hash });
};

const getTokenForgot = ({ token, email }) => {
  return requester.get(`/api/users/token-forgot/${token}?email=${email}`);
};

const recover = ({ token, password, confirmPassword, email }) => {
  return requester.post(`/api/reset-password`, {
    email,
    token,
    password,
    password_confirmation: confirmPassword
  });
};

const AuthService = {
  login,
  forgot,
  getTokenForgot,
  recover,
  me,
  confirmeAccount
};

export default AuthService;
