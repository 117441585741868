import requester from '../requester';

const create = (data) => {
  return requester.post('/api/conservations', data);
};

const getAll = () => {
  return requester.get(`/api/conservations`);
};

const remove = (ids) => {
  return requester.delete(`/api/conservations`, { data: ids });
};

const update = (data, id) => {
  return requester.put(`/api/conservations/${id}`, data);
};

const SectorConservationService = {
  create,
  getAll,
  remove,
  update
};

export default SectorConservationService;
