import requester from '../requester';

const create = (data) => {
  return requester.post('/api/sector-cca', data);
};

const getAll = (data) => {
  return requester.get(`/api/sector-cca?${new URLSearchParams(data)}`);
};

const remove = (id) => {
  return requester.delete(`/api/sector-cca/${id}`);
};

const update = (data, id) => {
  return requester.put(`/api/sector-cca/${id}`, data);
};

const SectorTrafficService = {
  create,
  getAll,
  remove,
  update
};

export default SectorTrafficService;
