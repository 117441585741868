import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sectorEngineeringScheduleProgressService from '../../services/SectorEngineeringScheduleProgressService';
import { toast } from 'react-toastify';
import { closeDialog } from './dialog';
import { getEngineeringSchedule } from './sectorEngineeringSchedule';

const initialState = {
  sendingData: false
};

export const createEngineeringScheduleProgress = createAsyncThunk(
  'engineeringSchedulProgress/create',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Criando registro...');
    return sectorEngineeringScheduleProgressService
      .create(data)
      .then((res) => {
        toast.success('Registro criado com sucesso!');
        dispatch(getEngineeringSchedule());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao criar registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const updateEngineeringScheduleProgress = createAsyncThunk(
  'engineeringSchedulProgress/update',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Alterando registro...');
    return sectorEngineeringScheduleProgressService
      .update(data, data.uuid)
      .then((res) => {
        toast.success('Registro atualizado com sucesso!');
        dispatch(getEngineeringSchedule());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao atualizar o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const removeEngineeringScheduleProgress = createAsyncThunk(
  'engineeringSchedulProgress/remove',
  async (uuid, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Removendo registro...');
    return sectorEngineeringScheduleProgressService
      .remove(uuid)
      .then((res) => {
        toast.success('Registro removido com sucesso!');
        dispatch(getEngineeringSchedule());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error('Falha ao remover o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

const engineeringSchedule = createSlice({
  name: 'engineeringSchedule',
  initialState,
  reducers: {},
  extraReducers: {
    [createEngineeringScheduleProgress.fulfilled]: (state) => {
      state.sendingData = false;
    },
    [createEngineeringScheduleProgress.rejected]: (state) => {
      state.sendingData = false;
    },
    [createEngineeringScheduleProgress.pending]: (state) => {
      state.sendingData = true;
    },
    [updateEngineeringScheduleProgress.fulfilled]: (state) => {
      state.sendingData = false;
    },
    [updateEngineeringScheduleProgress.rejected]: (state) => {
      state.sendingData = false;
    },
    [updateEngineeringScheduleProgress.pending]: (state) => {
      state.sendingData = true;
    }
  }
});

const { reducer } = engineeringSchedule;

export const {} = engineeringSchedule.actions;

export default reducer;
