// material-ui
import { Grid } from '@mui/material';
import LoadingContent from 'components/LoadingContent';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmeAccount } from 'store/reducers/auth';
import { Alert, AlertTitle, Box, Button } from '../../../node_modules/@mui/material/index';
import { useParams } from '../../../node_modules/react-router-dom/index';

// project import
import AuthWrapper from './AuthWrapper';

// ================================|| LOGIN ||================================ //

export default function ConfirmAccount() {
  const params = useParams();
  const dispatch = useDispatch();
  const confirmationAccount = useSelector((state) => state.auth.confirmationAccount);
  const confirmeAccountDetail = useSelector((state) => state.auth.confirmeAccountDetail);

  console.log(confirmeAccountDetail);

  useEffect(() => {
    dispatch(confirmeAccount({ hash: params.hash, id: params.id }));
  }, [params]);

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {confirmationAccount && <LoadingContent />}
          {confirmeAccountDetail && confirmeAccountDetail?.status === 200 && (
            <>
              <Alert severity="success">
                <AlertTitle>Pronto!</AlertTitle>
                Sua conta foi confirmada com sucesso!
              </Alert>

              <Box mt={2} display={'flex'} justifyContent={'center'}>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  Ir para o login
                </Button>
              </Box>
            </>
          )}

          {confirmeAccountDetail && confirmeAccountDetail?.status !== 200 && (
            <>
              <Alert severity="error">
                <AlertTitle>Ops...</AlertTitle>
                Tivemos um problema ao tentar confirmar a sua conta! Verifique se o link está correto e tente novamente.
              </Alert>
            </>
          )}
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}
