import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));
const ManageUser = Loadable(lazy(() => import('pages/manage-users/ManageUsers')));
const SectorTraffic = Loadable(lazy(() => import('pages/sector-traffic/SectorTraffic')));
const SectorConservation = Loadable(lazy(() => import('pages/sector-conservation/SectorConservation')));
const SectorEnvironment = Loadable(lazy(() => import('pages/sector-environment/SectorEnvironment')));
const SectorEngineering = Loadable(lazy(() => import('pages/sector-engineering/SectorEngineering')));
const SectorOperationsRestrictions = Loadable(lazy(() => import('pages/sector-operations/SectorOperationsRestrictions')));
const SectorOperationsAccidents = Loadable(lazy(() => import('pages/sector-operations/SectorOperationsAccidents')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/gerenciar-usuarios',
      element: <ManageUser />,
      permission: 'Gerenciar usuários'
    },
    {
      path: '/setor-cca',
      element: <SectorTraffic />,
      permission: 'Setor CCA'
    },
    {
      path: '/setor-conservacao',
      element: <SectorConservation />,
      permission: 'Setor de Conservação'
    },
    {
      path: '/setor-meio-ambiente',
      element: <SectorEnvironment />,
      permission: 'Setor Meio Ambiente'
    },
    {
      path: '/setor-engenharia',
      element: <SectorEngineering />,
      permission: 'Setor de Engenharia'
    },
    {
      path: '/setor-operacoes-acidentes',
      element: <SectorOperationsAccidents />,
      permission: 'Setor de Op. Acidentes'
    },
    {
      path: '/setor-operacoes-restricoes',
      element: <SectorOperationsRestrictions />,
      permission: 'Setor de Op. Restrições'
    }
  ]
};

export default MainRoutes;
