import requester from '../requester';

const create = ({ name }) => {
  return requester.post('/api/teams', { name });
};

const getAll = () => {
  return requester.get(`/api/teams`);
};

const TeamService = {
  create,
  getAll
};

export default TeamService;
