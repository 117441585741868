import requester from '../requester';

const getAll = () => {
  return requester.get(`/api/plans`);
};

const PlanService = {
  getAll
};

export default PlanService;
