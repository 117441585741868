// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import TutorialDataService from "../services/TutorialService";
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import teamService from '../../services/TeamService';

const initialState = {
  teams: [],
  fetchingTeams: false
};

export const getTeams = createAsyncThunk('team/getAll', async (_, { rejectWithValue, fulfillWithValue }) => {
  return teamService
    .getAll()
    .then((res) =>
      fulfillWithValue({
        status: res.status,
        data: res.data
      })
    )
    .catch((err) => {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

const team = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers: {
    [getTeams.rejected]: (state) => {
      state.fetchingTeams = false;
    },
    [getTeams.pending]: (state) => {
      state.fetchingTeams = true;
    },
    [getTeams.fulfilled]: (state, action) => {
      state.fetchingTeams = false;
      state.teams = action.payload.data;
    }
  }
});

const { reducer } = team;

export const {} = team.actions;

export default reducer;
