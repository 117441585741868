// material-ui
// project import

// assets

// ==============================|| DRAWER CONTENT - NAVIGATION CARD ||============================== //

const NavCard = () => <></>;

export default NavCard;
