import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import registerAccidentsService from '../../services/RegisterAccidentsService';
import sectorOperationRestrictionService from '../../services/SectorOperationRestrictionService';
import sectorOperationAccidents from '../../services/SectorOperationsAccidents';
import { closeDialog } from './dialog';

const initialState = {
  fetchingAccidents: false,
  fetchingRestrictions: false,
  creatingAccident: false,
  creatingRestriction: false,
  registeringAccident: false,
  updateRestrictionResult: null,
  accidents: [],
  restrictions: []
};

export const getAccidents = createAsyncThunk('operations/getAccidents', async (data, { rejectWithValue, fulfillWithValue }) => {
  return sectorOperationAccidents
    .getAll(data)
    .then((res) => {
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

export const createAccidents = createAsyncThunk(
  'operations/createAccidents',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Criando registro...');
    return sectorOperationAccidents
      .create(data)
      .then((res) => {
        toast.success('Registro criado com sucesso!');
        dispatch(getAccidents());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao criar registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const registerAccidents = createAsyncThunk(
  'operations/registerAccidents',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Atualizando registro...');
    return registerAccidentsService
      .update(data, data.uuid)
      .then((res) => {
        toast.success('Registro atualizado com sucesso!');
        dispatch(getAccidents());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao atualizar registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const updateAccident = createAsyncThunk(
  'operations/updateAccident',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Alterando registro...');
    return sectorOperationAccidents
      .update(data, data.uuid)
      .then((res) => {
        toast.success('Registro atualizado com sucesso!');
        dispatch(getAccidents());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao atualizar o registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const removeAccident = createAsyncThunk('operations/removeAccident', async (id, { rejectWithValue, fulfillWithValue, dispatch }) => {
  const loading = toast.loading('Removendo registro...');
  return sectorOperationAccidents
    .remove(id)
    .then((res) => {
      toast.success('Registro removido com sucesso!');
      dispatch(getAccidents());
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      toast.error(err.response.data.error ?? 'Falha ao remover o registro');
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    })
    .finally(() => {
      toast.dismiss(loading);
    });
});

export const createRestriction = createAsyncThunk(
  'operations/createRestriction',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Criando registro...');
    return sectorOperationRestrictionService
      .create(data)
      .then((res) => {
        toast.success('Registro criado com sucesso!');
        dispatch(getRestrictions());
        dispatch(closeDialog());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao criar registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const updateRestriction = createAsyncThunk(
  'operations/updateRestriction',
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Atualizando registro...');
    return sectorOperationRestrictionService
      .update(data, data.uuid)
      .then((res) => {
        toast.success('Registro criado com sucesso!');
        dispatch(getRestrictions());

        if (!data.end_time) {
          dispatch(closeDialog());
        }

        return fulfillWithValue({
          status: res.status,
          data: res.data,
          payload: data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao atualizar registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const removeRestriction = createAsyncThunk(
  'operations/removeRestriction',
  async (uuid, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const loading = toast.loading('Removendo registro...');
    return sectorOperationRestrictionService
      .remove(uuid)
      .then((res) => {
        toast.success('Registro removido com sucesso!');
        dispatch(getRestrictions());
        return fulfillWithValue({
          status: res.status,
          data: res.data
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error ?? 'Falha ao atualizar registro');
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data
        });
      })
      .finally(() => {
        toast.dismiss(loading);
      });
  }
);

export const getRestrictions = createAsyncThunk('operations/getRestrictions', async (uuid, { rejectWithValue, fulfillWithValue }) => {
  return sectorOperationRestrictionService
    .getAll()
    .then((res) => {
      return fulfillWithValue({
        status: res.status,
        data: res.data
      });
    })
    .catch((err) => {
      toast.error(err.response.data.error ?? 'Falha ao consultar registros');
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    })
    .finally(() => {});
});

const operation = createSlice({
  name: 'operation',
  initialState,
  reducers: {
    resetUpdateRestrictionResult: (state) => {
      state.updateRestrictionResult = null;
    }
  },
  extraReducers: {
    [getAccidents.fulfilled]: (state, action) => {
      state.accidents = action.payload.data;
      state.fetchingAccidents = false;
    },
    [getAccidents.rejected]: (state) => {
      state.accidents = [];
      state.fetchingAccidents = false;
    },
    [getAccidents.pending]: (state) => {
      state.fetchingAccidents = true;
    },
    [createAccidents.fulfilled]: (state) => {
      state.creatingAccident = false;
    },
    [createAccidents.rejected]: (state) => {
      state.creatingAccident = false;
    },
    [createAccidents.pending]: (state) => {
      state.creatingAccident = true;
    },
    [updateAccident.fulfilled]: (state) => {
      state.creatingAccident = false;
    },
    [updateAccident.rejected]: (state) => {
      state.creatingAccident = false;
    },
    [updateAccident.pending]: (state) => {
      state.creatingAccident = true;
    },
    [registerAccidents.fulfilled]: (state) => {
      state.registeringAccident = false;
    },
    [registerAccidents.rejected]: (state) => {
      state.registeringAccident = false;
    },
    [registerAccidents.pending]: (state) => {
      state.registeringAccident = true;
    },
    [createRestriction.fulfilled]: (state) => {
      state.creatingRestriction = false;
    },
    [createRestriction.rejected]: (state) => {
      state.creatingRestriction = false;
    },
    [createRestriction.pending]: (state) => {
      state.creatingRestriction = true;
    },
    [updateRestriction.fulfilled]: (state, action) => {
      state.creatingRestriction = false;
      state.updateRestrictionResult = action.payload;
    },
    [updateRestriction.rejected]: (state) => {
      state.creatingRestriction = false;
    },
    [updateRestriction.pending]: (state) => {
      state.creatingRestriction = true;
    },
    [getRestrictions.fulfilled]: (state, action) => {
      state.fetchingRestrictions = false;
      state.restrictions = action.payload.data;
    },
    [getRestrictions.rejected]: (state) => {
      state.fetchingRestrictions = false;
    },
    [getRestrictions.pending]: (state) => {
      state.fetchingRestrictions = true;
    }
  }
});

const { reducer } = operation;

export const { resetUpdateRestrictionResult } = operation.actions;

export default reducer;
