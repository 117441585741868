// assets
import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';
import {
  BuildOutlined,
  CarCrash,
  Engineering,
  NatureOutlined,
  NaturePeopleOutlined,
  TollOutlined
} from '../../node_modules/@mui/icons-material/index';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'sectors',
  title: 'Setores',
  type: 'group',
  children: [
    {
      id: 'sector_operations_accidents',
      title: 'Setor de Op. Acidentes',
      type: 'item',
      url: '/setor-operacoes-acidentes',
      icon: CarCrash,
      target: false
    },
    {
      id: 'sector_operations_restrictions',
      title: 'Setor de Op. Restrições',
      type: 'item',
      url: '/setor-operacoes-restricoes',
      icon: BuildOutlined,
      target: false
    },
    {
      id: 'sector_traffic',
      title: 'Setor CCA',
      type: 'item',
      url: '/setor-cca',
      icon: TollOutlined,
      target: false
    },
    {
      id: 'sector_conservation',
      title: 'Setor de Conservação',
      type: 'item',
      url: '/setor-conservacao',
      icon: NatureOutlined,
      target: false
    },
    {
      id: 'sector_environment',
      title: 'Setor Meio Ambiente',
      type: 'item',
      url: '/setor-meio-ambiente',
      icon: NaturePeopleOutlined,
      target: false
    },
    {
      id: 'sector_engineering',
      title: 'Setor de Engenharia',
      type: 'item',
      url: '/setor-engenharia',
      icon: Engineering,
      target: false
    }
  ]
};

export default pages;
