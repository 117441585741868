// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import TutorialDataService from "../services/TutorialService";
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import planService from '../../services/PlanService';

const initialState = {
  plans: [],
  fetching: false
};

export const getPlans = createAsyncThunk('plangetAll', async (_, { rejectWithValue, fulfillWithValue }) => {
  return planService
    .getAll()
    .then((res) =>
      fulfillWithValue({
        status: res.status,
        data: res.data
      })
    )
    .catch((err) => {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data
      });
    });
});

const plan = createSlice({
  name: 'plan',
  initialState,
  reducers: {},
  extraReducers: {
    [getPlans.rejected]: (state) => {
      state.fetching = false;
    },
    [getPlans.pending]: (state) => {
      state.fetching = true;
    },
    [getPlans.fulfilled]: (state, action) => {
      state.fetching = false;
      state.plans = action.payload.data;
    }
  }
});

const { reducer } = plan;

export const {} = plan.actions;

export default reducer;
